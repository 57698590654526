@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.container {
  position: absolute;
  inset: 0;
}

.colorPrimary {
  color: colors.$primary;
}

.description {
  color: colors.$black64;
}

.rightContent {
  margin: 48px 0;
  position: absolute;
  inset: 0;
}

.colorPrimary {
  color: colors.$primary;
}

.semiBold {
  font-weight: 600;
}
