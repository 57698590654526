@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "sass:color" as color;

.container {
  display: block;

  background: colors.$primary10;
  border: 1px solid colors.$primary;
  border-radius: 4px;

  color: colors.$primary;
  font-family: fonts.$fontSans;
  padding: 24px 36px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
}

.divider {
  background-color: colors.$primary;
  margin-top: 24px;
}

.button {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  color: colors.$primary;

  &:hover,
  &:focus-visible {
    color: color.adjust(colors.$primary, $lightness: -10%);
  }

  &:active {
    color: color.adjust(colors.$primary, $lightness: -20%);
  }
}

.causesContainer {
  margin-top: 24px;
  font-family: monospace;
}
