@mixin setColorNormal($bg, $fg) {
  color: $fg;
  background-color: $bg;
}

@mixin setColorHover($bg, $fg) {
  &:hover,
  &:focus-visible {
    color: $fg;
    background-color: $bg;
  }
}

@mixin setColorActive($bg, $fg) {
  &:active {
    color: $fg;
    background-color: $bg;
  }
}

@mixin setColorDisabled($bg, $fg) {
  &:disabled {
    color: $fg;
    background-color: $bg;
  }
}

@mixin makeButton() {
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  transition-duration: 125ms;
  transition-property: background-color, color, border-color, outline-width,
    opacity, transform;
  user-select: none;

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

// https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll
@mixin hideScrollBar() {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
