$zIndexNavbar: 100;
$zIndexModal: 200;
$zIndexDropdown: 300;
$zIndexTooltip: 400;
$zIndexToast: 500;

$navbarHeight: 80px;

// https://getbootstrap.com/docs/5.0/layout/breakpoints/
$breakpoint_xs: 0;
$breakpoint_sm: 576px;
$breakpoint_md: 768px;
$breakpoint_lg: 992px;
$breakpoint_xl: 1200px;
$breakpoint_xxl: 1400px;
