.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: hidden;
  padding: 64px 0;
}

.firstRowSlide {
  display: flex;
  gap: 16px;
  animation: firstRow 100s linear infinite;
}

// 2320px = [250 (width) + 24 (padding) + 16 (gap)] * 8

@keyframes firstRow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-2320px));
  }
}

.secondRowSlide {
  display: flex;
  gap: 16px;
  animation: secondRow 100s linear infinite;
}

@keyframes secondRow {
  0% {
    transform: translateX(-2320px);
  }
  100% {
    transform: translateX(0);
  }
}
