@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;

.beforeHeading {
  all: unset;
  display: block;

  color: colors.$black;
  font-size: 14px;
  font-family: fonts.$sans;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  all: unset;
  display: block;

  color: colors.$black;
  font-size: 80px;
  font-family: fonts.$serif;
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 56px;
}

.twoColumns {
  background-color: colors.$backgroundDark;
  border-radius: 4px;
  overflow: hidden;
}
