@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;

.container {
  background-color: colors.$backgroundLight;
  overflow-x: hidden;
}

.backdropContainer {
  position: relative;
}

.backdrop {
  position: absolute;
  inset: 0 0 auto 0;
  height: 120vh;
  transform: translateY(-50%);
  background-image: linear-gradient(
    rgba(0, 0, 0, 100%) 0%,
    rgba(0, 0, 0, 100%) 45%,
    rgba(0, 0, 0, 0%) 100%
  );

  @media (min-width: variables.$breakpoint_xl) {
    max-height: 100vw;
  }
}

.sectionEventsFromRibbons {
  margin: 12px 24px 12px 24px;
}

.containerStats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 120px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: 56px 0;
}

.statsNumber {
  font-family: fonts.$fontSerif;
  font-size: 48px;
}

.statsLabel {
  font-family: fonts.$fontSans;
  font-size: 16px;
  padding-left: 12px;
}

.containerFigureBottom {
  display: flex;
  padding: 80px 0;
  overflow: hidden;
}

.figureBottom {
  height: 600px;
  animation: figureBottom 100s infinite linear;
  padding: 0 10px;
}

@keyframes figureBottom {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2952px);
  }
}
