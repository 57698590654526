@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;

.body {
  background-color: colors.$black;
}

.container {
  display: flex;
  flex-direction: column;

  &.minHeight100vh {
    min-height: 100vh;
  }
}

.content {
  flex: 1 0 auto;
}

.backgroundDefault {
  background-color: transparent;
}

.backgroundLight {
  background-color: colors.$backgroundLight;
}

.backgroundWhite {
  background-color: colors.$white;
}
