@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "sass:color" as color;

@mixin lineClamp($limit) {
  @supports (-webkit-line-clamp: #{$limit}) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: #{$limit};
  }
}

// https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll
@mixin hideScrollBar() {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

@mixin setColorNormal($bg, $fg) {
  color: $fg;
  background-color: $bg;
}

@mixin setColorHover($bg, $fg) {
  &:hover,
  &:focus-visible {
    color: $fg;
    background-color: $bg;
  }
}

@mixin setColorActive($bg, $fg) {
  &:active {
    color: $fg;
    background-color: $bg;
  }
}

@mixin setColorDisabled($bg, $fg) {
  &:disabled {
    color: $fg;
    background-color: $bg;
  }
}

@mixin formatAsLink($alwaysUnderline: false) {
  cursor: pointer;
  text-decoration-color: transparent;
  text-decoration-line: underline;
  transition-duration: 125ms;
  transition-property: color, text-decoration-color;

  @if $alwaysUnderline {
    text-decoration-color: currentColor;
  }

  &:hover,
  &:focus-visible {
    text-decoration-color: theme.$colorLnHover;
    color: theme.$colorLnHover;
  }

  &:active {
    text-decoration-color: theme.$colorLnActive;
    color: theme.$colorLnActive;
  }

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-color: theme.$colorLnMain;
  }

  &[href$="#disabled"] {
    pointer-events: none;
    text-decoration-color: theme.$colorLnDisabled;
    color: theme.$colorLnDisabled;
  }
}

// https://getbootstrap.com/docs/5.0/layout/breakpoints/
@mixin media-xs() {
  @content;
}

@mixin media-sm() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin media-md() {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media-lg() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin media-xl() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin media-xxl() {
  @media (min-width: 1400px) {
    @content;
  }
}

// alias of media-xxl
@mixin media-xx() {
  @media (min-width: 1400px) {
    @content;
  }
}
