@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.root {
  color: colors.$black;
  display: flex;
  flex-direction: column;
  font-family: fonts.$sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  gap: 24px;
  justify-content: center;
  line-height: 175%;
  padding: 72px 32px 72px 96px;
}

.beforeTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
}

.title {
  font-family: fonts.$serif;
  font-size: 64px;
  font-weight: 500;
  line-height: 125%;

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink();
  }
}

.stats {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;

  em {
    font-family: fonts.$serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
  }

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink();
  }
}

.schedule {
  em {
    color: colors.$black;
    font-family: fonts.$serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
  }
}

.actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 24px;

  em {
    font-family: fonts.$serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
  }
}
