@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;

.container {
  display: flex;
  position: absolute;
  inset: 0;
}

.video {
  display: flex;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.slotIcons {
  align-items: center;
  display: flex;
  font-size: 32px;
  gap: 12px;
  height: auto;
  left: 16px;
  position: absolute;
  top: 16px;
  width: auto;

  &.countingDown {
    animation-name: disappear;
    animation-duration: var(--countdown-duration, 250ms);
    animation-timing-function: linear;
  }
}

@keyframes disappear {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}
