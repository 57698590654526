@use "sass:math" as math;
@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;

$breakpoint: variables.$breakpoint_xl;

@function exp($x) {
  @return math.pow(math.$e, $x);
}

@mixin setPaddingTopOnDesktop() {
  $ratioResistance: 12;
  $targetedAspectRatio: 1392 / 672;
  $minAspectRatio: exp(-1 / $ratioResistance) * $targetedAspectRatio;
  $maxAspectRatio: exp(+1 / $ratioResistance) * $targetedAspectRatio;
  $marginCloseness: 24;
  $estimatedInnerHeight: exp(-1 / $marginCloseness) * 100vh;
  $estimatedNavbarHeight: 80px + 64px;

  padding-top: clamp(
    #{1 / $maxAspectRatio} * 100%,
    #{$estimatedInnerHeight} - #{$estimatedNavbarHeight},
    #{1 / $minAspectRatio} * 100%
  );
}

@mixin setPaddingTopOnMobile() {
  $ratioResistance: 5;
  $targetedAspectRatio: 0.69;
  $minAspectRatio: exp(-1 / $ratioResistance) * $targetedAspectRatio;
  $maxAspectRatio: exp(+1 / $ratioResistance) * $targetedAspectRatio;
  $marginCloseness: 5;
  $estimatedInnerHeight: exp(-1 / $marginCloseness) * 100vh;
  $estimatedNavbarHeight: 80px + 152px;

  padding-top: clamp(
    #{1 / $maxAspectRatio} * 100%,
    #{$estimatedInnerHeight} - #{$estimatedNavbarHeight},
    #{1 / $minAspectRatio} * 100%
  );
}

.root {
  box-sizing: border-box;
  display: flex;
  height: 0;
  position: relative;
  width: 100%;

  @include setPaddingTopOnMobile();

  @media (min-width: $breakpoint) {
    @include setPaddingTopOnDesktop();
  }
}

.content {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
}

.top {
  flex: 0 0 auto;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  position: relative;

  @media (min-width: $breakpoint) {
    flex-direction: row;
  }
}

.left {
  flex: 1 1 0;
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  flex: 1 1 0;
  min-width: 0;
  position: relative;
}

.bottom {
  flex: 0 0 auto;
}
