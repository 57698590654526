@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.container {
  position: absolute;
  inset: 0;
}

.colorPrimary {
  color: colors.$primary;
}

.timer {
  color: colors.$primary;
  font-family: fonts.$sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.titleContent {
  @include mixins.lineClamp(4);
}

.rightContent {
  margin: 48px 0;
  position: absolute;
  inset: 0;
  cursor: pointer;
}

.bidderViewerWinner {
  font-family: fonts.$sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}

.colorPrimary {
  color: colors.$primary;
}

.semiBold {
  font-weight: 600;
}
