$onPrimary_colorBgMain: var(--on-primary--color-bg-main);
$onPrimary_colorBgHover: var(--on-primary--color-bg-hover);
$onPrimary_colorBgActive: var(--on-primary--color-bg-active);
$onPrimary_colorFgMain: var(--on-primary--color-fg-main);
$onPrimary_colorBgDisabled: var(--on-primary--color-bg-disabled);
$onPrimary_colorFgDisabled: var(--on-primary--color-fg-disabled);
$onSecondary_colorBgMain: var(--on-secondary--color-bg-main);
$onSecondary_colorBgHover: var(--on-secondary--color-bg-hover);
$onSecondary_colorBgActive: var(--on-secondary--color-bg-active);
$onSecondary_colorFgMain: var(--on-secondary--color-fg-main);
$onSecondary_colorBgDisabled: var(--on-secondary--color-bg-disabled);
$onSecondary_colorFgDisabled: var(--on-secondary--color-fg-disabled);
$onTertiary_colorBgMain: var(--on-tertiary--color-bg-main);
$onTertiary_colorBgHover: var(--on-tertiary--color-bg-hover);
$onTertiary_colorBgActive: var(--on-tertiary--color-bg-active);
$onTertiary_colorFgMain: var(--on-tertiary--color-fg-main);
$onTertiary_colorBgDisabled: var(--on-tertiary--color-bg-disabled);
$onTertiary_colorFgDisabled: var(--on-tertiary--color-fg-disabled);
$onNavbar_colorBgMain: var(--on-navbar--color-bg-main);
$onNavbar_colorFgMain: var(--on-navbar--color-fg-main);
$onNavbar_colorFgBorder: var(--on-navbar--color-fg-border);
$onNavbar_colorBgHover: var(--on-navbar--color-bg-hover);
$onNavbar_colorFgHover: var(--on-navbar--color-fg-hover);
$onNavbar_colorBgActive: var(--on-navbar--color-bg-active);
$onNavbar_colorFgActive: var(--on-navbar--color-fg-active);
$onNavbar_colorBgDisabled: var(--on-navbar--color-bg-disabled);
$onNavbar_colorFgDisabled: var(--on-navbar--color-fg-disabled);
$colorBgGround: var(--color-bg-ground);
$colorBgMain: var(--color-bg-main);
$colorBgSurface: var(--color-bg-surface);
$colorBgPaper: var(--color-bg-paper);
$colorBgBackdrop: var(--color-bg-backdrop);
$colorBgSelected: var(--color-bg-selected);
$colorBgHover: var(--color-bg-hover);
$colorBgActive: var(--color-bg-active);
$colorBgNavbar: var(--color-bg-navbar);
$colorFgMain: var(--color-fg-main);
$colorFgDimmed: var(--color-fg-dimmed);
$colorFgDisabled: var(--color-fg-disabled);
$colorFgDivider: var(--color-fg-divider);
$colorFgPrimary: var(--color-fg-primary);
$colorFgSecondary: var(--color-fg-secondary);
$colorLnMain: var(--color-ln-main);
$colorLnHover: var(--color-ln-hover);
$colorLnActive: var(--color-ln-active);
$colorLnDisabled: var(--color-ln-disabled);
$shadow: var(--shadow);
