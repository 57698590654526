@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;

.container {
  min-height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.button {
  all: unset;
  display: flex;

  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
  user-select: none;

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
  }

  &::before {
    content: "";
    display: flex;
    position: absolute;
    inset: 0;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px colors.$black24;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 250ms;
  }

  &.active::before {
    opacity: 100%;
  }
}

.buttonContent {
  background-color: colors.$grayOpaque32;
  position: absolute;
  inset: 0;
  border-radius: 4px;
  overflow: hidden;

  &::after {
    content: "";
    display: flex;
    position: absolute;
    inset: 0;
    border: 1px solid colors.$black;
    border-radius: 4px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 250ms;
  }

  .active &::after {
    opacity: 100%;
  }
}

.tooltipContent {
  border-radius: 4px;
  padding: 8px 12px;
  background-color: colors.$black;
  color: white;
  font-weight: 500;
  font-size: 14px;
}
