@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "sass:color" as color;

.avatarContainer {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.name {
  @include mixins.lineClamp(1);
  font-family: fonts.$fontSans;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.work {
  font-family: fonts.$fontSerif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.dot {
  font-family: fonts.$fontSans;
  color: colors.$black30;
  padding: 0 6px;
}

.imageRowContainer {
  height: 155px;
  overflow: hidden;
}

.artworkContainer {
  width: 175px;
  height: 155px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;

  &:hover,
  &:focus-visible {
    transform: scale(0.95);
  }
}

.nameViewer {
  a {
    color: colors.$black;
    font-family: fonts.$fontSans;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;

    &:hover,
    &:focus-visible {
      color: colors.$primary;
    }

    &:active {
      color: color.adjust(colors.$primary, $lightness: -20%);
    }
  }
}

.censored {
  filter: blur(10px);
}
