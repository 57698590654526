.container {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.file {
  border: 0;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.slotIcons {
  display: flex;
  flex-wrap: wrap;
  font-size: 32px;
  gap: 12px;
  height: auto;
  left: 0;
  max-width: 50%;
  padding: 16px;
  position: absolute;
  top: 0;
  width: auto;

  &.countingDown {
    animation-name: disappear;
    animation-duration: var(--countdown-duration, 250ms);
    animation-timing-function: linear;
  }
}

@keyframes disappear {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}
