@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  animation-duration: 125ms;
  animation-timing-function: ease-out;
  background-color: theme.$colorBgMain;
  border-radius: 4px;
  box-shadow: theme.$shadow;
  color: theme.$colorFgMain;
  font-family: fonts.$sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  max-width: 360px;
  padding: 8px 12px;
  user-select: none;
  white-space: pre-wrap;
  will-change: opacity;
  z-index: variables.$zIndexTooltip;

  &[data-state="open"] {
    animation-name: appear;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
