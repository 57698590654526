@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;

.container {
  position: absolute;
  inset: 0;
}

.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  --scrollbar-size: 4px;
}

.viewport {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.scrollbar {
  display: flex;
  user-select: none;
  touch-action: none;
  background-color: colors.$black08;
  transition-property: background-color;
  transition-duration: 250ms;

  &:hover {
    background-color: colors.$black16;
  }

  &[data-orientation="vertical"] {
    width: var(--scrollbar-size);
  }

  &[data-orientation="horizontal"] {
    flex-direction: column;
    height: var(--scrollbar-size);
  }
}

.thumb {
  flex: 1;
  background-color: colors.$black;
  border-radius: var(--scrollbar-size);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 48px;
    min-height: 48px;
  }
}

.corner {
  background-color: colors.$black08;
}
