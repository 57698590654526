@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.container {
  position: absolute;
  inset: 0;
}

.colorPrimary {
  color: colors.$primary;
}

.timer {
  color: colors.$primary;
  font-family: fonts.$sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.titleContent {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rightContent {
  position: absolute;
  inset: 0;
  margin: 48px 0;
  cursor: pointer;
}

.brushStroke {
  filter: hue-rotate(26.68deg);
  object-fit: contain;
  opacity: 40%;
}

.grid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  height: 520px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 12px;
  row-gap: 12px;
  justify-items: stretch;
  align-items: stretch;
}

.gridCell {
  background-color: colors.$black64;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
