@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;

.container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: stretch;

  &.backgroundDefault {
    background-color: transparent;
  }

  &.backgroundWhite {
    background-color: colors.$white;
  }
}

.content {
  display: block;
  flex: 1 1 0;
  min-width: 560;

  .sizeDefault & {
    max-width: 1440px;
  }

  .sizeSm & {
    max-width: 768px;
  }

  .paddingDefault & {
    padding-left: 56px;
    padding-right: 56px;
  }
}
