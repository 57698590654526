@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;

.containerStats {
  flex-wrap: wrap;
  padding: 56px 0;
}

.statsNumber {
  font-family: fonts.$fontSerif;
  font-size: 48px;
}

.statsLabel {
  font-family: fonts.$fontSans;
  font-size: 16px;
  padding-left: 12px;
}
