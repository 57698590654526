@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.container {
  position: absolute;
  inset: 0;
}

.colorFgPrimary {
  color: theme.$colorFgPrimary;
}

.titleContent {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rightContent {
  position: absolute;
  inset: 0;
  margin: 48px 0;
  cursor: pointer;
}
