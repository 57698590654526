.root {
  box-sizing: content-box;
  display: flex;
  height: 0;
  padding-top: clamp(
    var(--min-height-per-100pc, 0.25) * 100%,
    var(--optimal-height-per-100vh, 0.5) * 100vh,
    var(--max-height-per-100pc, 4) * 100%
  );
  position: relative;
  width: 100%;
}

.rootContent {
  display: flex;
  position: absolute;
  inset: 0;

  &[data-orientation="landscape"] {
    flex-direction: row;
  }

  &[data-orientation="portrait"] {
    flex-direction: column;
  }
}

.left {
  display: block;
  position: relative;
  flex: var(--left-ratio, 0.5) var(--left-ratio, 0.5)
    calc(var(--left-ratio, 0.5) * 100%);
}

.right {
  display: block;
  position: relative;
  flex: var(--right-ratio, 0.5) var(--right-ratio, 0.5)
    calc(var(--right-ratio, 0.5) * 100%);
}
