@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "sass:color" as color;

.container {
  position: relative;
}

.filledContainer {
  position: absolute;
  inset: 0;
}

.censorContainer {
  background: colors.$black30;
  backdrop-filter: blur(12px);
  position: absolute;
  inset: 0;
}

.buttonUnderline {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: colors.$white;
  text-decoration-line: underline;
  background-color: transparent;
  font-family: fonts.$fontSans;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    color: colors.$primary;
  }

  &:active {
    color: color.adjust(colors.$primary, $lightness: -20%);
  }
}

.censorText {
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  font-family: fonts.$fontSans;
  color: colors.$white;
}
