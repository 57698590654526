@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "sass:color" as color;

@mixin onNarrowScreen {
  @media (max-width: 939px) {
    @content;
  }
}

.link {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid theme.$colorFgDivider;
  color: theme.$colorFgMain;
  outline: none;
}

.text {
  font-size: 14px;
}

.container {
  margin-left: auto;
  margin-right: auto;
  font-family: fonts.$fontSans;
  color: theme.$colorFgMain;
}

.link:hover {
  color: theme.$onSecondary_colorFgMain;
  background-color: theme.$onSecondary_colorBgMain;
}

.tagline {
  font-family: fonts.$fontSerif;
}

.textLink {
  all: unset;
  cursor: pointer;
  color: theme.$colorFgMain;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    color: theme.$colorFgDimmed;
  }
}

@include onNarrowScreen {
  .content {
    margin-left: auto;
    margin-right: auto;
  }
}

.divider {
  all: unset;
  display: flex;

  height: 0px;
  border-top: 1px solid theme.$colorFgDivider;
}
