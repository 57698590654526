@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.container {
  display: flex;
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: flex;
    width: 100%;
    padding-top: calc(100% / var(--aspect-ratio, 1));
  }
}

.backdrop {
  background-color: theme.$colorBgBackdrop;
}
