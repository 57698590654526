@use "sass:color" as color;

/*
  Useful links:
  - https://sass-lang.com/documentation/modules
  - https://sass-lang.com/documentation/variables
  - https://sass-lang.com/documentation/at-rules/use
*/

$white: #ffffff;
$white96: rgba($white, 0.96);
$white80: rgba($white, 0.8);
$white64: rgba($white, 0.64);
$white56: rgba($white, 0.56);
$white50: rgba($white, 0.5); // deprecated
$white48: rgba($white, 0.48);
$white32: rgba($white, 0.32);
$white30: rgba($white, 0.3); // deprecated
$white24: rgba($white, 0.24);
$white16: rgba($white, 0.16);
$white10: rgba($white, 0.1); // deprecated
$white08: rgba($white, 0.08);

$black: #000000;
$black96: rgba($black, 0.96);
$black80: rgba($black, 0.8);
$black64: rgba($black, 0.64);
$black56: rgba($black, 0.56);
$black50: rgba($black, 0.5); // deprecated
$black48: rgba($black, 0.48);
$black32: rgba($black, 0.32);
$black30: rgba($black, 0.3); // deprecated
$black24: rgba($black, 0.24);
$black16: rgba($black, 0.16);
$black10: rgba($black, 0.1); // deprecated
$black08: rgba($black, 0.08);

$primary: #d02f2d;
$primary96: rgba($primary, 0.96);
$primary80: rgba($primary, 0.8);
$primary64: rgba($primary, 0.64);
$primary56: rgba($primary, 0.56);
$primary50: rgba($primary, 0.5); // deprecated
$primary48: rgba($primary, 0.48);
$primary32: rgba($primary, 0.32);
$primary30: rgba($primary, 0.3); // deprecated
$primary24: rgba($primary, 0.24);
$primary16: rgba($primary, 0.16);
$primary10: rgba($primary, 0.1); // deprecated
$primary08: rgba($primary, 0.08);

$green: #006e46;
$green96: rgba($green, 0.96);
$green80: rgba($green, 0.8);
$green64: rgba($green, 0.64);
$green56: rgba($green, 0.56);
$green50: rgba($green, 0.5); // deprecated
$green48: rgba($green, 0.48);
$green32: rgba($green, 0.32);
$green30: rgba($green, 0.3); // deprecated
$green24: rgba($green, 0.24);
$green16: rgba($green, 0.16);
$green10: rgba($green, 0.1); // deprecated
$green08: rgba($green, 0.08);

$ink: #1f1f1e;
$ink96: rgba($ink, 0.96);
$ink80: rgba($ink, 0.8);
$ink64: rgba($ink, 0.64);
$ink56: rgba($ink, 0.56);
$ink50: rgba($ink, 0.5); // deprecated
$ink48: rgba($ink, 0.48);
$ink32: rgba($ink, 0.32);
$ink30: rgba($ink, 0.3); // deprecated
$ink24: rgba($ink, 0.24);
$ink16: rgba($ink, 0.16);
$ink10: rgba($ink, 0.1); // deprecated
$ink08: rgba($ink, 0.08);

$grayOpaque96: color.adjust(white, $lightness: -96%);
$grayOpaque80: color.adjust(white, $lightness: -80%);
$grayOpaque64: color.adjust(white, $lightness: -64%);
$grayOpaque56: color.adjust(white, $lightness: -56%);
$grayOpaque50: color.adjust(white, $lightness: -50%); // deprecated
$grayOpaque48: color.adjust(white, $lightness: -48%);
$grayOpaque40: color.adjust(white, $lightness: -40%);
$grayOpaque32: color.adjust(white, $lightness: -32%);
$grayOpaque30: color.adjust(white, $lightness: -30%); // deprecated
$grayOpaque24: color.adjust(white, $lightness: -24%);
$grayOpaque16: color.adjust(white, $lightness: -16%);
$grayOpaque10: color.adjust(white, $lightness: -10%); // deprecated
$grayOpaque08: color.adjust(white, $lightness: -8%);

$backgroundLight: #fffff3;
$backgroundLightAlt: #f4f4e6;
$backgroundDark: #d5bc8f;
