@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.container {
  display: block;
  background-color: var(--navbar-background-color, #{theme.$colorBgNavbar});
  background-image: linear-gradient(#{theme.$colorBgNavbar}, transparent);
  height: variables.$navbarHeight;
  color: colors.$white;
  transition-property: background-color;
  transition-duration: 1000ms;
}

.content {
  height: 100%;
  box-sizing: border-box;
}

.slogan {
  color: colors.$white;
  font-family: fonts.$fontSerif;
}
