@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.container {
  display: flex;
  background-color: #f4f4e6;
  padding: 12px;
  border-radius: 4px;
  gap: 12px;
  // We need stable width for the smooth scrolling animation
  min-width: 250px;
  max-width: 250px;
  transition-property: background-color;
  transition-duration: 250ms;
  text-decoration: none;
  color: colors.$black;
}

.container:hover {
  cursor: pointer;
  background-color: #fefef2;
  box-shadow: 0px 60px 24px rgba(84, 84, 59, 0.01),
    0px 34px 20px rgba(84, 84, 59, 0.02), 0px 15px 15px rgba(84, 84, 59, 0.03),
    0px 4px 8px rgba(84, 84, 59, 0.04), 0px 0px 0px rgba(84, 84, 59, 0.04);
}

.avatar {
  flex: 0 0 auto;
  width: 73px;
  height: 73px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stats {
  font-family: fonts.$fontSerif;
  font-size: 14px;
  line-height: 24px;
}

.dot {
  font-family: fonts.$fontSans;
  color: colors.$black30;
  padding: 0 6px;
}

.nameViewer {
  font-family: fonts.$fontSans;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}
