@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;

.justOnboarded {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: fonts.$fontSans;
}

.title {
  font-weight: 500;
  font-size: 80px;
  line-height: 100px;
  margin-top: 16px;
  font-family: fonts.$fontSerif;
}

.divider {
  margin-top: 48px;
  margin-bottom: 48px;
}
