@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.input {
  all: unset;
  display: block;

  box-sizing: border-box;
  color: theme.$onNavbar_colorFgMain;
  flex: 1 1 64px;
  font-family: fonts.$sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  line-height: 150%;
  margin: 0 -4px 0 -12px;
  min-width: 64px;
  padding: 4px 4px 4px 12px;

  &::placeholder {
    color: theme.$onNavbar_colorFgMain;
    opacity: 0.64;
  }
}

.buttonClear {
  @include mixins.setColorNormal(transparent, colors.$white32);
  @include mixins.setColorHover(transparent, colors.$white48);
  @include mixins.setColorActive(transparent, colors.$white32);
  @include mixins.setColorDisabled(transparent, colors.$white16);
}
