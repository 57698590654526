.verticalLine {
  border-left-width: 1px;
  border-left-color: currentColor;
  box-sizing: border-box;
  flex: 0 0 auto;
  height: 1em;
  width: 1px;

  &.dashed {
    border-left-style: dashed;
  }

  &.solid {
    border-left-style: solid;
  }
}
