@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;

.container {
  display: block;
}

.navbarPlaceholder {
  height: variables.$navbarHeight;
}

.fixedContainer {
  display: block;
  height: auto;
  inset: 0 0 auto 0;
  position: fixed;
  z-index: variables.$zIndexNavbar;
}

.staticContainer {
  position: static;
  opacity: 0;
}

@media (min-width: 0px) {
  .navbar0 {
    display: block;
  }
  .navbar768 {
    display: none;
  }
  .navbar1200 {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar0 {
    display: none;
  }
  .navbar768 {
    display: block;
  }
  .navbar1200 {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar0 {
    display: none;
  }
  .navbar768 {
    display: none;
  }
  .navbar1200 {
    display: block;
  }
}
