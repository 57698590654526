@use "common-utils/styles/_mixins.scss" as mixins;
@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;

.container {
  all: unset;
  display: inline-flex;

  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  transition-duration: 125ms;
  transition-property: background-color, color, border-color, outline-width,
    opacity, transform;
  user-select: none;

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
  }

  &:disabled {
    cursor: not-allowed;
  }

  @include mixins.setColorNormal(colors.$grayOpaque16, colors.$black);
  @include mixins.setColorHover(colors.$grayOpaque32, colors.$black);
  @include mixins.setColorActive(colors.$grayOpaque48, colors.$black);
  @include mixins.setColorDisabled(colors.$grayOpaque16, colors.$black48);

  padding: 4px 8px;
  font-size: 14px;
  line-height: 150%;
}

.children {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
