.image {
  object-fit: contain;
  opacity: 0;

  &:nth-child(1) {
    animation: 1s ease 1s appear;
    animation-fill-mode: both;
  }
  &:nth-child(2) {
    animation: 1s ease 0.4s appear;
    animation-fill-mode: both;
  }
  &:nth-child(n + 3) {
    animation: 2s ease 2s appear;
    animation-fill-mode: both;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
