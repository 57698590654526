@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;
@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.arrowDropDown {
  color: theme.$onNavbar_colorFgMain;
  opacity: 64%;
}

.value {
  font-family: fonts.$fontSans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  span {
    text-transform: none;
    color: theme.$onNavbar_colorFgMain;
    opacity: 64%;
  }

  strong {
    text-transform: none;
    color: theme.$onNavbar_colorFgMain;
    font-weight: 500;
  }
}

.content {
  z-index: variables.$zIndexDropdown;
  width: var(--radix-select-trigger-width, 64px);
  max-height: var(--radix-select-content-available-height, 48px);
  box-shadow: theme.$shadow;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
}

.viewport {
  background-color: theme.$colorBgSurface;
}

.item {
  all: unset;
  display: block;

  color: theme.$colorFgMain;
  cursor: pointer;
  font-family: fonts.$fontSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 12px 16px;
  transition-duration: 250ms;
  transition-property: background-color, color;

  &[data-highlighted] {
    background-color: theme.$colorBgSelected;
    color: theme.$colorFgMain;
  }
}

.buttonScope {
  @include mixins.setColorNormal(transparent, theme.$colorFgMain);
  @include mixins.setColorHover(theme.$colorBgHover, theme.$colorFgMain);
  @include mixins.setColorActive(theme.$colorBgActive, theme.$colorFgMain);
  @include mixins.setColorDisabled(transparent, theme.$colorFgMain);

  padding: 4px;
  margin: -4px;
  outline: none !important;
}

.arrow {
  fill: theme.$colorBgSurface;
}
