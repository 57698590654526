@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;

.container {
  position: absolute;
  inset: 0;
}

.fileContainerBackground {
  background-color: colors.$black96;
}

.buttonSize {
  width: 88px;
  height: 88px;
  box-sizing: border-box;
}

.buttonColor {
  @include mixins.setColorNormal(colors.$black32, colors.$white48);

  :where(.twoColumns:hover) & {
    color: colors.$white80;
    background-color: colors.$black48;
  }

  @include mixins.setColorHover(colors.$black, colors.$white);
  @include mixins.setColorActive(colors.$black, colors.$white48);
  @include mixins.setColorDisabled(colors.$black16, colors.$white24);
}

.buttonLeft {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttonRight {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 100%) 100%
  );
}

.artworkTitle {
  color: colors.$backgroundLight;
  font-size: 16px;
  font-family: fonts.$sans;
  font-weight: 500;
  line-height: 175%;

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink($alwaysUnderline: false);
  }
}

.artworkOwnerLabel {
  color: colors.$backgroundLight;
  font-size: 14px;
  font-family: fonts.$serif;
  line-height: 175%;
  font-style: italic;
}

.artworkOwnerValue {
  color: colors.$backgroundLight;
  font-size: 14px;
  font-family: fonts.$serif;
  line-height: 175%;

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink($alwaysUnderline: false);
  }
}

.divider {
  all: unset;
  display: flex;
  width: 1px;
  min-width: 1px;
  height: auto;
  align-self: stretch;
  border-left: 1px solid colors.$white16;
}

.artworkCollectionLabel {
  color: colors.$backgroundLight;
  font-size: 14px;
  font-family: fonts.$serif;
  font-style: italic;
  line-height: 175%;
}

.artworkCollectionValue {
  color: colors.$backgroundLight;
  font-size: 14px;
  font-family: fonts.$serif;
  font-style: normal;
  line-height: 175%;

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink($alwaysUnderline: false);
  }
}
