@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.container {
  display: block;
  margin: 112px 0;
}

.listContainer {
  overflow: hidden;
  position: relative;
}

.list {
  scroll-behavior: smooth;
  @include mixins.hideScrollBar();
}

.listItem {
  position: absolute;
  inset: 0 16px;
  border-radius: 4px;
  overflow: hidden;
}

.button {
  &.buttonSize {
    width: 88px;
    height: 88px;
    box-sizing: border-box;
  }

  &.buttonColor {
    color: colors.$white50;
    background-color: colors.$black30;

    :where(.listContainer:hover) & {
      color: colors.$white80;
      background-color: colors.$black50;
    }

    &:hover,
    &:focus-visible {
      color: colors.$white;
      background-color: colors.$black;
    }

    &:active {
      color: colors.$white50;
      background-color: colors.$black;
    }

    &:disabled {
      color: colors.$white30;
      background-color: colors.$black10;
    }
  }
}

.buttonLeft {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttonRight {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.beforeHeading {
  all: unset;
  display: block;

  color: colors.$black;
  font-family: fonts.$fontSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: uppercase;
}

.heading {
  all: unset;
  display: block;

  color: colors.$black;
  font-family: fonts.$fontSerif;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}
