@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.container {
  color: theme.$colorFgMain;
  font-family: fonts.$sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink();
  }
}

.popoverDisplayName {
  color: theme.$colorFgMain;
  font-family: fonts.$sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;

  &.popoverAnonymous {
    color: theme.$colorFgDimmed;
  }

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink();
  }
}

.textWallet {
  color: theme.$colorFgMain;
  font-family: fonts.$serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
}

.popoverAddress {
  all: unset;
  display: inline;

  color: theme.$colorFgMain;
  font-family: fonts.$sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @include mixins.formatAsLink($alwaysUnderline: true);
}

.colorFgDimmed {
  color: theme.$colorFgDimmed;
}
