.divider {
  padding: 0;
  border: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.horizontal {
  height: 1px;
  min-height: 1px;
  width: 100%;
}

.vertical {
  height: auto;
  width: 1px;
  min-width: 1px;
  display: flex;
  align-self: stretch;
}

.colorBlack10 {
  background-color: rgba(0, 0, 0, 0.1);
}

.colorBlack05 {
  background-color: rgba(0, 0, 0, 0.05);
}

.colorWhite10 {
  background-color: rgba(255, 255, 255, 0.1);
}

.colorWhite05 {
  background-color: rgba(255, 255, 255, 0.05);
}

.colorWhite30 {
  background-color: rgba(255, 255, 255, 0.3);
}
