@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "sass:color" as color;

@mixin onNarrowScreen {
  @media (max-width: 950px) {
    @content;
  }
}

@mixin onMediumScreen {
  @media (max-width: 1919px) {
    @content;
  }
}

.container {
  margin: 0 32px;
  background-color: #d7c096;
  border-radius: 8px;
  background-image: url("./images/background.png");
  background-blend-mode: overlay;
  background-repeat: repeat;
  overflow: hidden;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  padding-left: 32px;
}

.divider {
  margin: 10px 0;
}

.artworkTitleContainer {
  margin-bottom: 24px;
  padding: 0 32px;

  font-size: 64px;
  line-height: 80px;
  font-family: fonts.$fontSerif;
  font-weight: 500;

  @include mixins.lineClamp(2);
}

.artworkTitle {
  all: unset;
  display: inline;

  @include mixins.formatAsLink($alwaysUnderline: false);
}

.artist {
  display: flex;
  gap: 12px;
  margin-bottom: 32px;
  color: colors.$black;
  text-decoration: none;
  padding: 0 32px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  flex: 0 0 auto;
}

.divider {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.16);
}

.artistStats {
  font-family: fonts.$fontSerif;
  font-size: 14px;
  line-height: 24px;
}

.dot {
  font-family: fonts.$fontSans;
  color: colors.$black30;
  padding: 0 6px;
}

.descriptionContainer {
  position: relative;
  padding: 0 32px;
}

.description {
  font-size: 16px;
  line-height: 175%;
  color: colors.$black64;
  white-space: pre-wrap;
  font-family: fonts.$fontSans;

  padding: 0 32px;
  overflow-wrap: anywhere;

  p {
    all: unset;
    display: block;
  }

  p + p {
    margin-top: 0.875em;
  }

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink($alwaysUnderline: true);
  }
}

.image {
  overflow: hidden;
  position: relative;
}

.nameViewer {
  a {
    color: colors.$black;
    font-family: fonts.$fontSans;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;

    @include mixins.formatAsLink($alwaysUnderline: false);
  }
}

.fileContainerBackground {
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 250ms;

  &:hover {
    background-color: colors.$black16;
  }
}

.fileViewer {
  transition-duration: 250ms;
  transition-property: filter;
  &:hover {
    filter: brightness(0.8);
  }
}

.column {
  position: absolute;
  inset: 0;
}

.iconSingleEdition {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
