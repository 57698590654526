@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;
@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_theme.scss" as theme;

.container {
  background-color: theme.$onNavbar_colorBgMain;
  border-radius: 4px;
  border: 1px solid theme.$onNavbar_colorFgBorder;
  box-sizing: border-box;
  height: 48px;
  padding: 2px 24px;
  position: relative;
  transition-duration: 250ms;
  transition-property: border-color, opacity;

  &:hover {
    border: 1px solid theme.$onNavbar_colorFgBorder;
    opacity: 100%;
  }

  &:focus-within {
    border: 1px solid theme.$onNavbar_colorFgBorder;
    opacity: 100%;
  }
}

.iconSearch {
  color: theme.$onNavbar_colorFgMain;
  opacity: 64%;
  transition-property: color;
  transition-duration: 250ms;
}
