@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;

.container {
  position: absolute;
  inset: 0;

  background-image: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%22542%22%20height%3D%22313%22%20viewBox%3D%220%200%20542%20313%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20opacity%3D%220.04%22%3E%3Cpath%20d%3D%22M480.704%2082.1625C493.746%2096.5083%20501.571%20112.158%20501.571%20138.242C501.571%20183.888%20468.967%20224.317%20423.321%20245.183L411.583%20228.229C454.621%20204.754%20463.75%20174.758%20466.358%20155.196C459.838%20159.108%20450.708%20160.413%20441.579%20159.108C418.104%20156.5%20399.846%20138.242%20399.846%20113.463C399.846%20101.725%20405.063%2089.9875%20412.888%2080.8584C422.017%2071.7292%20432.45%2067.8167%20445.492%2067.8167C459.838%2067.8167%20472.879%2074.3375%20480.704%2082.1625ZM350.287%2082.1625C363.329%2096.5083%20371.154%20112.158%20371.154%20138.242C371.154%20183.888%20338.55%20224.317%20292.904%20245.183L281.167%20228.229C324.204%20204.754%20333.333%20174.758%20335.942%20155.196C329.421%20159.108%20320.292%20160.413%20311.162%20159.108C287.687%20156.5%20269.429%20138.242%20269.429%20113.463C269.429%20101.725%20274.646%2089.9875%20282.471%2080.8584C290.296%2071.7292%20302.033%2067.8167%20315.075%2067.8167C329.421%2067.8167%20342.462%2074.3375%20350.287%2082.1625Z%22%20fill%3D%22black%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.rightContent {
  position: absolute;
  inset: 0;
}

.commentContainer {
  position: relative;
  margin: 48px 12px;
}

.commentContent {
  padding: 0 40px;
  font-size: 20px;
  font-family: fonts.$serif;
  line-height: 175%;
  overflow-wrap: anywhere;

  p {
    all: unset;
    display: block;
  }

  p + p {
    margin-top: 0.875em;
  }

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink($alwaysUnderline: true);
  }
}

.commentAuthor {
  visibility: visible;
}

.avatarContainer {
  border-radius: 4px;
  overflow: hidden;
}

.commentAuthorName {
  color: colors.$black;
  font-size: 16px;
  font-family: fonts.$sans;
  font-weight: 500;
  line-height: 175%;

  a {
    all: unset;
    display: inline;
    @include mixins.formatAsLink();
  }
}

.commentAuthorDescription {
  color: colors.$black64;
  font-size: 14px;
  font-family: fonts.$sans;
  line-height: 175%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
