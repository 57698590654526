.container {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.slotIcons {
  display: flex;
  flex-wrap: wrap;
  font-size: 32px;
  gap: 12px;
  height: auto;
  left: 0;
  max-width: 50%;
  opacity: 100%;
  padding: 16px;
  position: absolute;
  top: 0;
  transition-duration: 125ms;
  transition-property: opacity;
  width: auto;

  .container:hover & {
    opacity: 0%;
  }
}
