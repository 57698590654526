@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_mixins.scss" as mixins;

.root {
  background-color: colors.$backgroundLight;
  border-radius: 8px;
}

.main {
  overflow: hidden;
}

.mainContent {
  position: absolute;
  inset: 0;
}

.buttonNavigateLeft {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-16px, -50%);
}

.buttonNavigateRight {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(+16px, -50%);
}

.buttonNavigateLeft,
.buttonNavigateRight {
  border-radius: 64px !important;
  @include mixins.setColorNormal(colors.$black16, colors.$white);
  @include mixins.setColorHover(colors.$black, colors.$white);
  @include mixins.setColorActive(colors.$black, colors.$white80);
  @include mixins.setColorDisabled(colors.$black16, colors.$white16);
}
