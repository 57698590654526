.fill {
  display: flex;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.fixed {
  display: flex;
  position: relative;
  width: var(--width, auto);
  height: var(--height, auto);
}

.autoHeight {
  display: flex;
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: flex;
    width: 100%;
    padding-top: calc(100% / var(--aspect-ratio, 1));
  }
}

.backgroundDefault {
  background-color: var(--background-color, rgba(0, 0, 0, 0.8));
}
